import zIndex from "@material-ui/core/styles/zIndex";
import { brown } from "@material-ui/core/colors";
import { ICONS } from '../../assets/icons/icons.js';

export default theme => ({
    root: {
        width: '100%',
        height: 'auto',
        overflow: 'scroll',
    },
    revealHeader: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        paddingTop: '18px',
        paddingBottom: '18px',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '18px',
        fontWeight: 'Bold',
        textAlign: 'center',
        color: '#FFFFFF',
        backgroundColor: '#000000',
        transform: 'translateY(-80px)',
        transition: 'all 0.2s linear', 
        zIndex: '20'
    },
    locationIcon: {
        position: 'absolute',
        right: '18px',
        width: '18px',
        height: '18px',
        backgroundColor: '#434343',
        mask: `url(${ICONS.location}) no-repeat center / contain`,
    },
    pageDescription: {
        width: '100%',
        heigth: '100%',
        marginTop: '8px',
        marginBottom: '8px',
        paddingLeft: '20px',
        paddingRight: '20px',
        fontSize: '37px',
        fontWeight: 'Bold',
        textAlign: 'center',
        lineHeight: '40px',
        color: '#FFFFFF',
    }, 
    gallery: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
        zIndex: '5'
    },
    bottomText: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        position: 'fixed',
        bottom: '12px',
        fontSize: '13px',
        fontWeight: 'lighter',
        textAlign: 'center',
        color: '#FFFFFF',
        zIndex: '20',
        opacity: '1',
        transition: 'all 0.2s linear',
    },
    bottomLogo: {
        width: '73px',
        marginLeft: '10px',
    },
    emptyMessage: {
        width: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    emptyMessageText: {
        fontSize: '16px',
        fontWeight: 'normal',
        textAlign: 'center',
        color: '#FFFFFF',
        margin: '13px auto'
    },
    emptyMessageArrow: {
        position: 'fixed',
        transform: "translateX(30px)",
        bottom: '110px'
    },
    addressBox: {
        width: '100%',
        background: '#151515 0% 0% no-repeat padding-box',
        marginTop: '10px',
        padding: '27px',
        color: '#FFFFFF',
        '& p': {
            fontSize: '18px',
            lineHeight: '23px',
            marginTop: '21px',
            marginBottom: '24px'
        }
    },
    addressTitle: {
        display: 'flex',
        alignItems: 'center',
        '& h3': {
            marginBottom: 0,
            marginLeft: '15px',
            fontSize: '18px',
            lineHeight: '23px'
        }
    },
    addressButtom: {
        width: '167px',
        height: '45px',
        backgroundColor: '#8C161E',
        borderRadius: '23px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        textTransform: 'uppercase',
        border: '2px solid #A0232B',
        boxShadow: '0px 3px 7px #00000066',
        '& a': {
            color: 'white',
            '&:hover': {
                textDecoration: 'none',
                color: 'white'
            }
        }
    }
})


    