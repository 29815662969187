export default theme => ({
    root: {
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
    },
    pageDescription: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        marginTop: '8px',
        marginBottom: '25px',
        paddingLeft: '15px',
        paddingRight: '15px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        fontWeight: 'lighter',
        textAlign: 'center',
        color: '#FFFFFF',
    },
    camContainer: {
        // width: '600px',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: 'brown',
        position: 'relative'
    },
    camera: {
        width: '100%',
    },
    bottomText: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        position: 'fixed',
        bottom: '12px',
        fontSize: '13px',
        fontWeight: 'lighter',
        textAlign: 'center',
        color: '#FFFFFF'
    },
    bottomLogo: {
        width: '73px',
        marginLeft: '10px',
    }
});