import React, { useEffect, useState } from 'react'
import { compose } from 'recompose';
import styles from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core';

import { ICONS } from '../../../assets/icons/icons.js';


const footerStatus = {
    state: {pathname: '/', visiable: false},
    setState(value) {
        this.state = value;
        this.setters.forEach(setter => setter(this.state));
    },
    setters: []
};

function useFooterStatus() {
    const [state, set] = useState(footerStatus.state);
    if (!footerStatus.setters.includes(set)) {
        footerStatus.setters.push(set);
    }
    useEffect(() => () => {
        footerStatus.setters = footerStatus.setters.filter(setter => setter !== set)
    }, []);
    return [state, footerStatus.setState];
}

footerStatus.setState = footerStatus.setState.bind(footerStatus);

const Footer = ({ classes}) => {

    const history = useHistory();
    const location = useLocation();
    const [footerStatus, setFooterStatus] = useFooterStatus({ pathname: '/', visiable: false });

    let search = null;
    let phone = null;
    try {
        search = location.state.search;
        phone = location.state.phone;
        console.log(search, phone);
    } catch (e) {
        search = null;
        phone= null
    }

    
    useEffect(() => {
        setFooterStatus({ pathname: location.pathname, visiable: false })
    }, [location.pathname])

    return (  
         <div className={classes.root} style={{ opacity: footerStatus.visiable ? '0' : '1' }}>
            <div className={classes.menuContainer}>
                <div className={classes.icon, footerStatus.pathname == "/search" ? classes.menuSearchActive : classes.searchIcon} onClick={() => { setFooterStatus({ pathname: '/search', visiable: footerStatus.visiable }); history.push("/search") }}></div>
                <div className={classes.icon, footerStatus.pathname == "/" ? classes.menuIconActive : classes.menuIcon} onClick={() => { setFooterStatus({ pathname: '/', visiable: footerStatus.visiable }); history.push("/") }}></div>
                <div className={classes.icon, footerStatus.pathname == "/qrcode" ? classes.qrCodeIconActive : classes.qrCodeIcon} onClick={() => { setFooterStatus({ pathname: '/qrcode', visiable: footerStatus.visiable }); history.push("/qrcode") }}></div>
            </div>
            {search && phone ? (
                <a className={classes.iconCall} href={`tel:${phone}`}>
                    <img src={ICONS.call} alt="" />
                </a>
            ) : null }
            
        </div>
    )
}


export default compose(withStyles(styles))(Footer);
export {
    useFooterStatus
}
