import React, { useState, useEffect, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import Header from '../../layout/components/Header';
import { Context } from '../../context';
import axios from "axios";
import { useHistory, useLocation } from 'react-router-dom';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import { useFooterStatus } from '../../layout/components/Footer/index.js';
import { ICONS } from '../../assets/icons/icons.js';
import { useLoadingStatus } from '../../screens/Loading/index.js';
import ImageCard from '../../components/ImageCard/index.js';
import Footer from '../../components/Footer/index.js';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const COMMERCE_ENDPOINT = process.env.REACT_APP_COMMERCE_ENDPOINT;

function GetMenus(code) {

    const options = {
        url: BASE_URL + "/" + COMMERCE_ENDPOINT + "/" + code.productId + '/menus/client-request',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        },
        data: {
            tenantId: code.tenantId,
            menuId: code.menuId
        }
    }
    return axios(options);
}

const CheckData = (state, dispatch, callback, setLoadingStatus, setGoToScan) => {

    const code = JSON.parse(sessionStorage.getItem("code"));


    //CHECK IF THERE A QR-CODE REGSITER
    if (Boolean(state.code)) {
        //CHECK IF THE CODES ARE DIFERENT
        // if(Boolean(state.code.menuId != state.data.id)){
        if (state.replace) {

            const clearData = { type: 'SET_DATA', payload: { id: "", name: "", address: "", locale: "", phone: "", zipcode: "", gallery: [] } };
            dispatch(clearData);

            setGoToScan(true);
            setLoadingStatus(false)

            GetMenus(state.code).then(result => {
                if (result.status == 200) {
                    const data = {
                        id: result.data.menu_id,
                        name: result.data.name,
                        gallery: result.data.content.assets,
                        address: result.data.address,
                        phone: result.data.phone,
                        zipcode: result.data.zipcode,
                        locale: result.data.locale

                    }
                    const response = { source: state.code, data: data, message: "New photos were added!", status: 201 }
                    setGoToScan(true);
                    callback(response)
                    //console.log(response);
                } else {
                    const response = { source: state.code, data: result, message: "Menu was not found!", status: 400 }
                    callback(response)
                    setGoToScan(false);
                    //console.log(response);
                }
            }).catch(e => {
                setGoToScan(false);
                const response = { source: state.code, error: e, message: "Failed during request of the menu!", status: 400 }
                callback(response)
                //console.log(response);
            });
            const action = { type: 'SET_REPLACE', payload: false };
            dispatch(action);
        }

        // } else {
        //     const response = { source: state.code, data: state.data, message:"Gallery loaded", status: 200}
        //     callback(response);
        //     console.log(response);
        // }

    } else if (Boolean(code)) {
        if (state.replace) {
            setLoadingStatus(false)
            const action = { type: 'SET_CODE', payload: code };
            dispatch(action);
        }
    } else {
        setLoadingStatus(false)
        const urlParams = new URLSearchParams(window.location.search);
        if (Boolean(urlParams.toString())) {
            const data = { tenantId: urlParams.get('tenant_id'), productId: urlParams.get('product_id'), menuId: urlParams.get('menu_id') }
            //console.log(data)
            const response = { source: state.code, data: data, message: "Code was added!", status: 203 }
            setGoToScan(true);
            callback(response)
            //console.log(response);
        } else {
            const response = { source: state.code, data: state.data, message: "Theres no data collected", status: 404 }
            setGoToScan(true);
            callback(response)
            //console.log(response);
        }
    }
}


const Home = ({ classes, t }) => {

    const location = useLocation();
    const history = useHistory();
    var timer = null
    const { state, dispatch } = useContext(Context);
    const [footerStatus, setFooterStatus] = useFooterStatus();
    const [scrollTop, setScrollTop] = useState(0);
    const [isHidden, setIsHidden] = useState(true);
    const [scrolling, setScrolling] = useState(false);
    const [logo, setLogo] = useState(false);
    const [loadingStatus, setLoadingStatus] = useLoadingStatus();
    const [goToScan, setGoToScan] = useState(true);

    let search = null;
    try {
        search = location.state.search;
    } catch (e) {
        search = null;
    }



    useEffect(() => {

        CheckData(state, dispatch, (response) => {
            if (response.status == 201) {
                const action = { type: 'SET_DATA', payload: response.data };
                setGoToScan(true);
                dispatch(action);
            } else if (response.status == 203) {
                const action = { type: 'SET_CODE', payload: response.data };
                setGoToScan(true);
                dispatch(action);
            } else if (response.status == 404) {
                // NEED SCAN MESSAGE
                history.push('/qrcode');
                setFooterStatus({ pathname: '/qrcode', visiable: footerStatus.visiable });
                setGoToScan(false);
            }
            setTimeout(() => { setLoadingStatus(true); }, 200);
            //setLoadingStatus(true);
        }, setLoadingStatus, setGoToScan);

        const onScroll = e => {
            let totalScroll = e.target.scrollHeight - e.target.clientHeight;
            setScrollTop(e.target.scrollTop);
            setIsHidden(e.target.scrollTop < 200);

            if (e.target.scrollTop < totalScroll - 100) {
                setLogo(false)
            } else {
                setLogo(true)
            }

            if (timer) {
                clearTimeout(timer)
            }

            timer = setTimeout(() => {
                timer = null;
                setScrolling(false);
                setFooterStatus({ pathname: '/', visiable: false });
            }, 1000);

            if (!scrolling) {
                setScrolling(true);
                setFooterStatus({ pathname: '/', visiable: true });
            }
        };

        document.getElementById('scrollDiv').addEventListener("scroll", onScroll);
        return () => document.getElementById('scrollDiv').removeEventListener("scroll", onScroll);

    }, [scrollTop, state.code]);


    const smoothScroolEvent = (section) => {
        var sectionElement = document.getElementById(section).offsetTop;
        const scrollDiv = document.getElementById('scrollDiv')
        scrollDiv.scrollTo({ top: sectionElement, left: 0, behavior: 'smooth' });
    }


    const getDirectionsHandle = () => {
        const {
            address,
            zipcode,
            locale
        } = { ...state.data }

        let query = `${address},${zipcode},${locale}`
        query = query.replace('undefined', '')
        query = encodeURIComponent(query)

        if ((navigator.platform.indexOf("iPhone") != -1) ||
            (navigator.platform.indexOf("iPad") != -1) ||
            (navigator.platform.indexOf("iPod") != -1))
            window.open(`https://maps.apple.com/?address=${query}`);
        else /* else use Google */
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${query}`);

    }




    return (
        <div id="scrollDiv" className={classes.root}>
            <div className={classes.revealHeader} style={{ transform: isHidden ? 'translateY(-80px)' : 'translateY(0px)' }}>
                {state.data.name.toUpperCase()}
                {search ? (
                    <div className={classes.locationIcon} onClick={() => smoothScroolEvent('address-box')}></div>
                ) : null}

            </div>
            <Header />
            <div className={classes.pageDescription}>{state.data.name.toUpperCase()}</div>
            <div className={classes.gallery} style={{ paddingBottom: '150px' }}>
                {
                    state.data.gallery.map((prop, key) => {
                        if (prop.enable) {
                            return (
                                <ImageCard key={key} tag={key} url={prop.url} />
                            );
                        }
                    })
                }
                {search && loadingStatus && (Boolean(state.data.address) || Boolean(state.data.zipcode) || Boolean(state.data.locale)) ? (
                    <div className={classes.addressBox} id="address-box">
                        <div className={classes.addressTitle}>
                            <img src={ICONS.location} alt="" />
                            <h3>{t('where we are')}</h3>
                        </div>
                        <p>{state.data.address} {state.data.zipcode} {state.data.locale}</p>
                        <div className={classes.addressButtom} onClick={getDirectionsHandle}>
                            {t('get directions')}
                        </div>
                    </div>
                ) : null}
            </div >

            <div className={classes.emptyMessage} style={{ height: window.innerHeight - 350 + 'px' }} hidden={state.data.gallery.length > 0} hidden={goToScan}>
                <div className={classes.emptyMessageText}>{t("press qrcode scan pt1")}</div>
                <img src={ICONS.qr_code_white} alt="" />
                <div className={classes.emptyMessageText}>{t("press qrcode scan pt2")}</div>
                <img className={classes.emptyMessageArrow} src={ICONS.red_arrow} alt="" />
            </div>
            <Footer scroll={state.data.gallery.length > 0 ? logo : true} />
        </div>
    )
}

export default compose(
    withStyles(styles),
    withTranslation()
)(Home)
