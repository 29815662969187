// NATIVE IMPORTS
import React, { useContext, useEffect } from 'react';
// MODULES IMPORTS
import QrReader from 'react-qr-reader';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
// COMPONENT IMPORTS
import Header from '../../layout/components/Header';
import Footer from '../../components/Footer/index.js';
import { useFooterStatus } from '../../layout/components/Footer/index.js';
import { Context } from '../../context';
// ASSET IMPORTS
import { ICONS } from '../../assets/icons/icons.js';
// STYLE IMPORTS
import styles from './styles';

const QrcodeScanner = ({ classes, t }) => {

    const history = useHistory();
    const { state, dispatch } = useContext(Context);
    const [footerStatus, setFooterStatus] = useFooterStatus();

    useEffect(() => {
        setFooterStatus({ pathname: '/qrcode', visiable: footerStatus.visiable })
    }, [])

    const handleScan = value => {
        if (value) {
            const newUrl = new URL(value);
            const urlParams = new URLSearchParams(newUrl.search);

            if (Boolean(urlParams.toString())) {
                const data = { tenantId: urlParams.get('tenant_id'), productId: urlParams.get('product_id'), menuId: urlParams.get('menu_id') }
                const action = { type: 'SET_CODE', payload: data };
                dispatch(action);
                const actionReplace = { type: 'SET_REPLACE', payload: true };
                dispatch(actionReplace);
                setFooterStatus({ pathname: '/', visiable: footerStatus.visiable })
                sessionStorage.setItem("code", JSON.stringify(data));
                history.push('/');
            }
        }
    }
    const handleError = err => {
        console.error(err)
        alert("Verifique as suas permissões do uso da câmera nas definições do seu browser");
    }

    return (
        <div className={classes.root}>
            <Header />
            <div className={classes.pageDescription}>{t('scan qrcode description')}</div>
            <div className={classes.camContainer} style={{ width: document.body.clientHeight - 132 }}>

                <QrReader
                    delay={1}
                    onError={(error) => handleError(error)}
                    onScan={(data) => handleScan(data)}
                    showViewFinder={false}
                    className={classes.camera}
                />

                <div style={{ width: document.body.clientWidth, height: document.body.clientHeight - 132, position: 'absolute', top: '0', left: '0' }}>
                    <div style={{ width: '100%', height: '15%', backgroundColor: 'rgba(0,0,0,0.5)' }}></div>
                    <div style={{ width: '100%', height: '50%', display: 'flex' }}>
                        <div style={{ width: (document.body.clientWidth - ((document.body.clientHeight - 132) / 2)) / 2, height: '100%', backgroundColor: 'rgba(0,0,0,0.5)' }}></div>
                        <div style={{ width: (document.body.clientHeight - 132) / 2, height: '100%', position: 'relative' }}>
                            <div style={{ width: '13%', height: '100%', position: 'absolute', top: '0', left: '0', borderTop: '4px solid #ffffff', borderBottom: '4px solid #ffffff', }}></div>
                            <div style={{ width: '13%', height: '100%', position: 'absolute', top: '0', right: '0', borderTop: '4px solid #ffffff', borderBottom: '4px solid #ffffff', }}></div>
                            <div style={{ width: '100%', height: '13%', position: 'absolute', top: '0', left: '0', borderRight: '4px solid #ffffff', borderLeft: '4px solid #ffffff', }}></div>
                            <div style={{ width: '100%', height: '13%', position: 'absolute', bottom: '0', right: '0', borderRight: '4px solid #ffffff', borderLeft: '4px solid #ffffff', }}></div>
                        </div>
                        <div style={{ width: (document.body.clientWidth - ((document.body.clientHeight - 132) / 2)) / 2, height: '100%', backgroundColor: 'rgba(0,0,0,0.5)' }}></div>
                    </div>
                    <div style={{ width: '100%', height: '35%', backgroundColor: 'rgba(0,0,0,0.5)' }}></div>
                </div>

            </div>
            <Footer scroll={true}/>
        </div>
    )
}
export default compose(
    withStyles(styles),
    withTranslation()
)(QrcodeScanner)
