// NATIVE IMPORTS
import React from 'react';
// STYLE
import './style.css';
// ICONS IMPORTS
import { ICONS } from '../../assets/icons/icons.js';

const APP_VERSION = process.env.REACT_APP_VERSION;

const Footer = (props) => {
    return(
        <div className="bottomText" style={{ opacity: props.scroll ? '1' : '0' }} >Powered by <img className="bottomLogo" src={ICONS.cycloid_logo} alt="" />|<span className="versionText">v{APP_VERSION}</span></div>
    );
}

export default Footer;