const Style = {
    root: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        objectFit: 'contain',
        overflow: 'hidden',
        backgroundColor: 'rgba(0,0,0,0.6)',
        zIndex: '20'
    },
    modalContainer: {
        width: '280px',
        height: 'auto',
        margin: 'auto',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        paddingLeft: '15px',
        paddingRight: '15px',
        borderRadius: '10px',
        backgroundColor: '#ffffff'
    },
    iconContainer: {
        width: '80px',
        height: '80px',
        display: 'flex',
        borderRadius: '7px',
        padding: '4px',
        position: 'absolute',
        top: '-20px',
        
        backgroundColor: '#ffffff'
    },
    icon: {
        width: '100%',
        objectFit: 'contain',
        margin: 'auto',
        borderRadius: '7px',
        boxShadow: '0px 3px 7px #00000066',
    },
    modalTitle: {
        width: '100%',
        height: 'auto',
        margin: '70px auto 10px auto',
        lineHeight: '26px',
        fontSize: '23px',
        fontWeight: 'normal',
        textAlign: 'center',
        color: '#000000'
    },
    modalDescription: {
        width: '100%',
        height: 'auto',
        margin: '10px auto 15px auto',
        lineHeight: '21px',
        fontSize: '16px',
        fontWeight: 'normal',
        textAlign: 'center',
        color: '#000000'
    },
    modalInfo: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: '21px',
        fontSize: '13px',
        fontWeight: 'normal',
        textAlign: 'center',
        color: '#6C6C6C'
    },
    modalAnchor: {
        width: '100%',
        height: 'auto',
        margin: '15px auto 20px auto',
        lineHeight: '21px',
        fontSize: '16px',
        fontWeight: 'normal',
        textAlign: 'center',
        color: '#8C161E'
    },
    modalButton: {
        width: '117px',
        height: '36px',
        display: 'flex',
        margin: '15px auto 10px auto',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '7px',
        backgroundColor: '#8C161E',
        fontSize: '16px',
        fontWeight: 'normal',
        textAlign: 'center',
        color: '#FFFFFF'
    }
}

export default Style;