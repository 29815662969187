import React, {useEffect, useState} from 'react';
import './style.css';
import Lottie from 'react-lottie';
import loadingAnimation from '../../assets/lottie/loading.json';

const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const ImageCard = (props) => {


    const [imageStatus, setImageStatus] = useState(false);

    useEffect(() => { 
        document.getElementById("menu-"+props.tag).addEventListener('load',function(){ setImageStatus(true)}, false);
    }, []);

    return (
        <div style={{width:'100%', height:'auto', position:'relative', marginTop: '20px', marginBottom: '20px' }}>
            <div style={{width:'100%', height:'100%', position:'absolute', backgroundColor: 'rgba(0,0,0,0.7)'}} hidden={imageStatus}>
                <div className="loading-overlay-container">
                    <Lottie options={loadingOptions} height={250} width={250} isStopped={false} isPaused={false} />
                </div>
            </div>
            <img id={"menu-"+props.tag} src={props.url} style={{ width: '100%', height: 'auto'}} alt="" />
        </div>
    )
}

export default ImageCard;