import React, { useState, useEffect } from 'react';
import Style from './style.js';
import { ICONS } from '../../assets/icons/icons.js';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const modalStatus = {
    state: { device: 'android', type: 'before', enable: false, action: () => { } },
    setState(value) {
        this.state = value;
        this.setters.forEach(setter => setter(this.state));
    },
    setters: []
};

function useModalStatus() {
    const [state, set] = useState(modalStatus.state);
    if (!modalStatus.setters.includes(set)) {
        modalStatus.setters.push(set);
    }
    useEffect(() => () => {
        modalStatus.setters = modalStatus.setters.filter(setter => setter !== set)
    }, []);
    return [state, modalStatus.setState];
}

modalStatus.setState = modalStatus.setState.bind(modalStatus);

const Modal = ({ t }) => {

    const [modalStatus, setModalStatus] = useModalStatus();

    useEffect(() => {
        console.log(modalStatus);
    }, [modalStatus])

    return (
        <div style={Style.root} hidden={!modalStatus.enable}>
            <div style={Style.modalContainer}>
                <div style={Style.iconContainer}>
                    <img style={Style.icon} src={ICONS.icon_app} alt="" />
                </div>
                <div style={Style.modalTitle}>{t(modalViews[modalStatus.device][modalStatus.type].title.value)}</div>
                <div style={Style.modalDescription}>{t(modalViews[modalStatus.device][modalStatus.type].description.value)}</div>
    <div style={Style.modalInfo} hidden={!modalViews[modalStatus.device][modalStatus.type].info.status}><div>{t('modal ios instructions before add app pt3')}</div><div style={{width: '25px', height: '25px', display: 'flex'}}><img src={ICONS.add} style={{width: '100%', objectFit: 'contain', margin: 'auto',}} alt=""/></div></div>
                <div style={Style.modalInfo} hidden={!modalViews[modalStatus.device][modalStatus.type].info.status}>{t('modal ios instructions before add app pt4')}</div>
                <div style={Style.modalButton} onClick={() => { modalStatus.action() }} hidden={!modalViews[modalStatus.device][modalStatus.type].button.status}>{t(modalViews[modalStatus.device][modalStatus.type].button.value)}</div>
    <div style={Style.modalAnchor} onClick={() => { setModalStatus({ ...modalStatus, enable: false }); }}>{t('modal button close add app')}</div>
            </div>
        </div>
    );
}

export default compose(
    withTranslation()
)(Modal)
export { useModalStatus };





const modalViews = {
    android: {
        before: {
            title: {
                value: "modal android title before add app pt1",
                status: true,
            },
            description: {
                value: "modal android description before add app pt2",
                status: true,
            },
            info: {
                info: "",
                status: false,
            },
            button: {
                value: "modal android button before add app pt3",
                status: true,
            }
        },
        after: {
            title: {
                value: "modal android title after add app pt1",
                status: true,
            },
            description: {
                value: "modal android description after add app pt2",
                status: true,
            },
            info: {
                info: "",
                status: false,
            },
            button: {
                value: "",
                status: false,
            }
        }
    },
    ios: {
        before: {
            title: {
                value: "modal ios title before add app pt1",
                status: true,
            },
            description: {
                value: "modal ios description before add app pt2",
                status: true,
            },
            info: {
                info: "",
                status: true,
            },
            button: {
                value: "",
                status: false,
            }
        },
        after: {
            title: {
                value: "modal ios title after add app pt1",
                status: true,
            },
            description: {
                value: "modal ios description after add app pt2",
                status: true,
            },
            info: {
                info: "",
                status: false,
            },
            button: {
                value: "",
                status: false,
            }
        }
    }
}