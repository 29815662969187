import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Warning from './screens/Warning/index.js';
import Modal from './screens/Modal/index.js';
import Loading from './screens/Loading/index.js';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import ContextProvider from './context';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './i18n';


const app = (
  <Suspense fallback={<div></div>}>
    <BrowserRouter>
      <ContextProvider>
        <Warning />
        <Modal />
        <Loading />
        <App />
      </ContextProvider>
    </BrowserRouter>
  </Suspense>
);


ReactDOM.render(app, document.getElementById('root'));
serviceWorker.register();
