// NATIVE IMPORTS
import React, { useEffect, useState } from 'react';
// MODULES IMPORTS
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
// COMPONENT IMPORTS
import Footer from '../../components/Footer/index.js';
// ASSETS
import { IMAGES } from '../../assets/images/images.js';
import { ICONS } from '../../assets/icons/icons.js';
// STYLES
import styles from './styles';


function CheckUserAgent(variables) {

    const userAgent = navigator.userAgent.toLowerCase();
    const uaString = userAgent;

    variables.isChrome = /chrome/.test(uaString);
    variables.isExplorer = /msie/.test(uaString);
    variables.isExplorer_11 = /rv:11/.test(uaString);
    variables.isFirefox = /firefox/.test(uaString);
    variables.isSafari = /safari/.test(uaString);
    variables.isOpera = /opr/.test(uaString);
    variables.isEdgeDesktop = /edge/.test(uaString);
    variables.isEdgeiOS = /edgios/.test(uaString);
    variables.isEdgeAndroid = /edga/.test(uaString);

    variables.isIOS = /ipad|iphone|ipod/.test(uaString);
    variables.isMobile = /mobile/.test(uaString);
    if ((variables.isChrome) && (variables.isSafari)) { variables.isSafari = false; }
    if ((variables.isChrome) && ((variables.isEdgeDesktop) || (variables.isEdgeiOS) || (variables.isEdgeAndroid))) { variables.isChrome = false; }
    if ((variables.isSafari) && ((variables.isEdgeDesktop) || (variables.isEdgeiOS) || (variables.isEdgeAndroid))) { variables.isSafari = false; }
    if ((variables.isChrome) && (variables.isOpera)) { variables.isChrome = false; }

    return variables;
}

const variables = {
    isChrome: "",
    isExplorer: "",
    isExplorer_11: "",
    isFirefox: "",
    isSafari: "",
    isOpera: "",
    isEdgeDesktop: "",
    isEdgeiOS: "",
    isEdgeAndroid: "",
    isIOS: "",
    isMobile: "",
}


const Warning = ({ classes, t }) => {

    const [screenRes, setScreenRes] = useState({ orientation: "", isEnable: true, isPortrait: true })

    const warnings = {
        deviceLock: { icon: ICONS.mobile_devices, description: t('incompatible device') },
        deviceOrientation: { icon: ICONS.rotate_mobile, description: t('change device orientation') },
    }

    useEffect(() => {
        onResize();
        window.addEventListener("orientationchange", (event) => { onResize(event) });
        return () => window.removeEventListener("orientationchange", (event) => { onResize(event) });
    }, []);

    function onResize(event) {
        const userAgent = CheckUserAgent(variables);
        let isEnable;
        let isPortrait;

        if (window.orientation === 90 || window.orientation === -90) {
            isEnable = false;
            isPortrait = false;
        } else {
            isEnable = true;
            isPortrait = true;
        }

        let isMobile = true;
        if (!(userAgent.isIOS || userAgent.isMobile)) { isEnable = false; isMobile = false };

        setScreenRes({ orientation: isPortrait ? 'PORTRAIT' : 'LANDSCAPE', isEnable: isEnable, isMobile: isMobile });
    }

    return (
        <div className={classes.root} style={{ display: screenRes.isEnable ? 'none' : 'flex' }}>
            <div className={classes.logo}>
                <img src={IMAGES.cyc_logo} alt="" />
            </div>
            <div className={classes.icon}>
                <img src={screenRes.isMobile ? warnings.deviceOrientation.icon : warnings.deviceLock.icon} alt="" />
            </div>
            <div className={classes.text}>{screenRes.isMobile ? warnings.deviceOrientation.description : warnings.deviceLock.description}</div>
            <Footer scroll={true} />
        </div>
    )
}

export default compose(
    withStyles(styles),
    withTranslation()
)(Warning)
