import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
//import loadingAnimation from '../../assets/lottie/loading.json';
import loadingAnimation from '../../assets/lottie/anim-panela.json';
import './style.css';

const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const loadingStatus = {
    state: true,
    setState(value) {
        this.state = value;
        this.setters.forEach(setter => setter(this.state));
    },
    setters: []
};


function useLoadingStatus() {
    const [state, set] = useState(loadingStatus.state);
    if (!loadingStatus.setters.includes(set)) {
        loadingStatus.setters.push(set);
    }
    useEffect(() => () => {
        loadingStatus.setters = loadingStatus.setters.filter(setter => setter !== set)
    }, []);
    return [state, loadingStatus.setState];
}

loadingStatus.setState = loadingStatus.setState.bind(loadingStatus);

const Loading = ({ t }) => {

    const [loadingStatus, setLoadingStatus] = useLoadingStatus();

    useEffect(() => {
    }, [loadingStatus])

    return (
        <div className="loading-root" hidden={loadingStatus}>
            <div className="loading-container">
                <Lottie options={loadingOptions} height={150} width={100} isStopped={false} isPaused={false} />
                <div className="loading-text">{t("loading content")}</div>
            </div>
        </div>
    );
}

export default compose(
    withTranslation()
)(Loading)

export { useLoadingStatus };