
export default theme => ({
    root: {
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
    },
    searchInputBox: {
        marginBottom: '20px',
        width: '100%',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        padding: '0 18px',
        '& .MuiFormControl-root': {
            width: '100%',
            height: '55px'
        },
        '& .MuiInputBase-root': {
            backgroundColor: '#212121',
            color: '#7C7C7C',
            borderRadius: '50px'
        }
    },
    searchContent: {
        color: 'white',
        width: '100%',
        height: 'calc(100vh - 160px)',
        overflow: 'scroll',
    },
    itemContainter: {
        width: '100%', 
        height: 'auto',
        display: 'flex', 
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center', 
        paddingLeft: '18px',
        paddingRight: '18px',
        zIndex: '5',
    },
    itemBox: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '70px',
        padding: '0 20px',
        background: '#151515 0% 0% no-repeat padding-box',
        borderBottom: '1px solid #2F2F2F',
        '& p': {
            fontSize: '16px',
            lineHeight: '23px',
            marginLeft: '20px',
            marginBottom: '0',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            lineClamp: 2, 
            boxOrient: 'vertical'
        },
        '& .arrow': {
            marginLeft: 'auto'
        }
    }
})