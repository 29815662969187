import { ICONS } from '../../../assets/icons/icons.js';

export default theme => ({
    root: {
        width: '100vw',//219px
        height: '65px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: '45px',
        opacity: '1',
        transition: 'all 0.2s linear',
        zIndex: '3'
    },
    menuContainer: {
        width: '224px',//219px
        height: '55px',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        backgroundColor: '#212121',
        borderRadius: '55px',
        border: '1px solid #8C161E21',
        boxShadow: '0px 3px 7px #00000066',
    },
    menuButtons:{
        width: '24px',
        height: '24px',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            height: '24px',
            objectFit: 'contain',
        }
    },
    icon: {
        width: '24px',
        height: '24px',
    },
    searchIcon: {
        width: '24px',
        height: '24px',
        backgroundColor: '#434343',
        mask: `url(${ICONS.search}) no-repeat center / contain`,
    },
    menuIcon: {
        width: '24px',
        height: '24px',
        backgroundColor: '#434343',
        mask: `url(${ICONS.menu}) no-repeat center / contain`,
    },
    qrCodeIcon: {
        width: '24px',
        height: '24px',
        backgroundColor: '#434343',
        mask: `url(${ICONS.qr_code}) no-repeat center / contain`,
    },
    menuSearchActive: {
        width: '24px',
        height: '24px',
        backgroundColor: '#FFFFFF',
        mask: `url(${ICONS.search}) no-repeat center / contain`,
    },
    menuIconActive: {
        width: '24px',
        height: '24px',
        backgroundColor: '#FFFFFF',
        mask: `url(${ICONS.menu}) no-repeat center / contain`,
    },
    qrCodeIconActive: {
        width: '24px',
        height: '24px',
        backgroundColor: '#FFFFFF',
        mask: `url(${ICONS.qr_code}) no-repeat center / contain`,
    },
    iconCall: {
        width: '55px',
        height: '55px',
        backgroundColor: '#006A25',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '10px'
    }
})