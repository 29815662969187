import React, {  } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';


import Home from './../screens/Home';
import QrcodeScanner from './../screens/QrCodeScanner';
import Search from './../screens/Search';





const Routes = () => {

    return (
        <Switch>
            <Route
                path="/"
                exact
                component={Home}
            />
            <Route
                path="/qrcode"
                exact
                component={QrcodeScanner}
            />
            <Route
                path="/search"
                exact
                component={Search}
            />
            <Redirect
                to="/"
            />
        </Switch>
    )
}

export default Routes;
