// NATIVE IMPORTS
import React, { useState } from 'react'
// MODULE IMPORTS
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Button, MenuItem, ListItemIcon, ClickAwayListener, Grow, Paper, Popper, MenuList } from '@material-ui/core';
// STYLES
import './style.css';
// ASSETS IMPORTS
import { IMAGES } from '../../../assets/images/images.js';
import { ICONS } from '../../../assets/icons/icons.js';

const languages = [
  { id: 'pt', label: 'Português', icon: ICONS.pt_flag },
  { id: 'en', label: 'Inglês', icon: ICONS.en_flag },
  { id: 'es', label: 'Espanhol', icon: ICONS.es_flag },
  { id: 'fr', label: 'Francês', icon: ICONS.fr_flag },
]

const Header = () => {

  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  const anchorRef = React.useRef(null);
  const currentLanguage = languages.find(language => language.id === i18n.language);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  
  const handleClickLanguage = (language) => {
    const lng = language.id;
    localStorage.setItem('lang', lng);
    i18next.changeLanguage(lng);
    setOpen(false);
  }

  return (
    <div className="root">
      <img src={IMAGES.cyc_logo} alt="" />

      <div className="language-container">
        <Button
          className="button-toogle-menu"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          startIcon={<img alt="flag" style={{width:'25px'}} src={currentLanguage.icon} />}
          endIcon={<img alt="flag" style={{width:'25px', margin:'-10px', transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 0.2s linear'}} src={ICONS.drop_arrow} />}
          onClick={handleToggle}
        >
          {/* {currentLanguage.label} */}
        </Button>

        <Popper
          className="menu"
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom-end' ? 'center top' : 'center bottom' }}
            >
              <Paper elevation={3} className="paper">
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" className="menu-list">
                    {languages.map((language) => (
                      <MenuItem
                        key={language.id}
                        className="menu-item"
                        onClick={() => handleClickLanguage(language)}>
                        <ListItemIcon>
                          {<img alt="flag" style={{width:'25px'}} src={language.icon} />}
                        </ListItemIcon>
                        {t(language.id)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>


    </div>
  )
}

export default Header;
