import React from 'react';
import styles from './styles.js';
import { makeStyles } from "@material-ui/core/styles";
import Footer from './components/Footer';

const useStyles = makeStyles(styles);

const Layout = (props) => {

  const classes = useStyles();
  const { children } = props;
  
  return (
    <div className={classes.layoutRoot}>
      <div className={classes.layoutCanvas}></div>
      <div className={classes.layoutChildren}>{children}</div>
      <Footer/>
    </div>
  );
}
export default Layout;