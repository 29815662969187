import React, { useState, useEffect } from 'react';
import Layout from './layout';
import Routes from './routes';
import { useModalStatus } from './screens/Modal/index.js';

function App() {

	const [isInstalled, setIsInstalled] = useState();
	const [modalStatus, setModalStatus] = useModalStatus();

	useEffect(() => {

		window.addEventListener('beforeinstallprompt', installProp );
		window.addEventListener('appinstalled', (e) => {
			setModalStatus({
				device: 'android', type: 'after', enable: true, action: () => { }
			});
		});

		const userAgent = navigator.userAgent.toLowerCase();
		const uaString = userAgent;
		const isIOS = /ipad|iphone|ipod/.test(uaString);

		if (isIOS) {

			const urlParams = new URLSearchParams(window.location.search);
			const param = urlParams.get('pwa_mode')

			if (param == "true") {
				setIsInstalled(true);
			} else {
				setIsInstalled(false);
			}

			if (!isInstalled) {
				setModalStatus({
					device: 'ios', type: 'before', enable: true, action: () => { }
				});
			} else {
				setModalStatus({
					device: 'ios', type: 'before', enable: false, action: () => { }
				});
			}
		}

	}, [isInstalled]);

	const installProp = e => {
		e.preventDefault();
		setModalStatus({
			device: 'android', type: 'before', enable: true, action: () => {
				e.prompt();
				e.userChoice.then((choiceResult) => {
					if (choiceResult.outcome === 'accepted') {
						setModalStatus({
							device: 'android', type: 'after', enable: false, action: () => { }
						});
						window.removeEventListener('beforeinstallprompt', installProp)
					} else {
						// NAO ACEITOU
					}
				});
			}
		});
		
	}

	return (
		<Layout>
			<Routes />
		</Layout>
	);
}

export default App;
