import { IMAGES } from '../assets/images/images.js';

export default theme => ({
    layoutRoot: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    layoutCanvas: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        backgroundColor: '#000000',
        objectFit: 'contain',
        overflow: 'hidden',
        backgroundImage: `url(${IMAGES.background})`,
        backgroundRepeat: 'repeat',
        zIndex: '1'
    },
    layoutChildren: {
        width: '100%',
        height: '100%',
        position: 'relative',
        zIndex: '2',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        overflow: 'hidden'
    }
})