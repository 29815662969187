import React, { useReducer, createContext } from "react";

export const Context = createContext();

const initialState = {
    showButtonInstall: false,
    installed: false,
    replace: true,
    code: null,
    data: {
        id:"",
        name: "",
        gallery: []
    }
}


export const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_BUTTOM_INSTALL': {
            return {
                ...state,
                showButtonInstall: action.payload
            }
        }
        case 'SET_INSTALLED': {
            return {
                ...state,
                installed: action.payload
            }
        }
        case 'SET_CODE': {
            return {
                ...state,
                code: action.payload
            }
        }
        case 'SET_DATA': {
            return {
                ...state,
                data: action.payload
            }
        }
        case 'SET_REPLACE': {
            return {
                ...state,
                replace: action.payload
            }
        }
        default:
            return state;
    }
}


const ContextProvider = (props) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };

    return (
        <Context.Provider value={value}>
            {props.children}
        </Context.Provider>
    )
}
export default ContextProvider;

export const withContext = WrappedComponent => props => (
    <Context.Consumer>
        {value => <WrappedComponent {...props} context={value} />}
    </Context.Consumer>
);

