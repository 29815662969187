export const ICONS = {
    add: require('./icon-add.svg'),
    menu: require('./icon-menu.svg'),
    drop_arrow: require('./drop-down-arrow.svg'),
    qr_code: require('./icon-qr-code.svg'),
    qr_code_white: require('./icon-qr-code-white.svg'),
    mobile_devices: require('./icon-smartphone-tablet.svg'),
    rotate_mobile: require('./icon-rotate-mobile.svg'),
    icon_app: require('./icon-app.png'),
    cycloid_logo: require('./cycloid_logo.svg'),
    red_arrow: require('./red_arrow.svg'),
    pt_flag: require('./pt-flag.svg'),
    en_flag: require('./en-flag.svg'),
    es_flag: require('./es-flag.svg'),
    fr_flag: require('./fr-flag.svg'),
    search: require('./icon-search.svg'),
    call: require('./icon-call.svg'),
    location: require('./icon-location.svg')
}
