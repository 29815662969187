import React, { useState, useContext, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import axios from "axios";
import { useHistory } from 'react-router-dom';
import {
    withStyles,
    TextField,
    InputAdornment
} from '@material-ui/core';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Footer from '../../components/Footer/index.js';
import Header from '../../layout/components/Header';
import SearchIcon from '@material-ui/icons/Search';
import { useFooterStatus } from '../../layout/components/Footer/index.js';
import { Context } from '../../context';

import Lottie from 'react-lottie';
import loadingAnimation from '../../assets/lottie/loading.json';

const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


const BASE_URL = process.env.REACT_APP_BASE_URL;
const COMMERCE_ENDPOINT = process.env.REACT_APP_COMMERCE_ENDPOINT;

function PostSearch(query) {

    const options = {
        url: BASE_URL + "/" + COMMERCE_ENDPOINT + "/search",
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        },
        data: {
            value: query
        }
    }
    return axios(options);
}

const Search = ({
    classes, t
}) => {

    const history = useHistory();
    const [isHidden, setIsHidden] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [results, setResults] = useState([])
    const [logo, setLogo] = useState(false);
    const { state, dispatch } = useContext(Context);

    var timer = null
    const [scrolling, setScrolling] = useState(false);
    const [footerStatus, setFooterStatus] = useFooterStatus();
    const [scrollTop, setScrollTop] = useState(0);
    const [searchHeight, setSearchHeight] = useState(false);

    const [loadRequest, setLoadRequest] = useState(true);
    const [noResults, setNoResults] = useState(true);

    var contentHeight = useRef();

    useEffect(() => {

        searchConatinerHandler();

        const onScroll = e => {
            let totalScroll = e.target.scrollHeight - e.target.clientHeight;
            setScrollTop(e.target.scrollTop);
            setIsHidden(e.target.scrollTop < 200);

            if (e.target.scrollTop < totalScroll - 50) {
                setLogo(false)
            } else {
                setLogo(true)
            }

            if (timer) {
                clearTimeout(timer)
            }

            timer = setTimeout(() => {
                timer = null;
                setScrolling(false);
                setFooterStatus({ pathname: '/search', visiable: false });
            }, 2000);

            if (!scrolling) {
                setScrolling(true);
                setFooterStatus({ pathname: '/search', visiable: true });
            }
        };

        document.getElementById('scrollSearch').addEventListener("scroll", onScroll);
        return () => document.getElementById('scrollSearch').removeEventListener("scroll", onScroll);

    }, [scrollTop]);

    const handlerSearch = async () => {
        try {
            setNoResults(false);
            setLoadRequest(false);
            const res = await PostSearch(searchValue)
            const data = res.data
            setLoadRequest(true);
            setNoResults(() => data.length > 0 ? true : false);
            setResults(data)
        } catch (error) {
            setResults([])
        }

    }

    const searchConatinerHandler = () => {
        var currentHeight = contentHeight.current.clientHeight;
        var maxHeight = window.innerHeight - 230;
        console.log(currentHeight);

        if (currentHeight < maxHeight) {
            console.log(false)
            setSearchHeight(false)
        } else {
            console.log(true)
            setSearchHeight(true)
        }
    }

    const clickResultItemHandle = (item) => {
        console.log(item);
        const data = { tenantId: item.tenant_id, productId: item.product_id, menuId: item.menu_id };
        const action = { type: 'SET_CODE', payload: data };
        dispatch(action);
        const actionReplace = { type: 'SET_REPLACE', payload: true };
        dispatch(actionReplace);
        sessionStorage.setItem("code", JSON.stringify(data));
        history.push({
            pathname: '/',
            state: { search: true, phone: Boolean(item.info) && Boolean(item.info.phone) ? item.info.phone : null }
        })
    }

    return (
        <div className={classes.root}>
            <Header />
            <div className={classes.searchInputBox}>
                <TextField
                    autoComplete="off"
                    id="outlined-basic"
                    variant="outlined"
                    placeholder={t("search placeholder")}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    onKeyPress={(event) => {
                        if (event.key === "Enter") {
                            handlerSearch()
                        }
                    }}
                    onChange={event => {
                        setSearchValue(event.target.value)
                    }}
                />
            </div>
            <div id="scrollSearch" className={classes.searchContent}>
                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems:'flex-start', justifyContent:'center'}} hidden={loadRequest}>
                    <Lottie options={loadingOptions} height={250} width={250} isStopped={false} isPaused={false} />
                </div>
                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems:'flex-start', justifyContent:'center'}} hidden={noResults}>
                    <div style={{color:'#ffffff', paddingTop:'50px'}}>{t("no results")}</div>
                </div>
                <div ref={contentHeight} className={classes.itemContainter} style={{ paddingBottom: '177px', marginBottom: '40px' }}>
                    {results.map((item) => (
                        <div className={classes.itemBox} key={item.product_id} onClick={() => clickResultItemHandle(item)}>
                            <MenuBookIcon />
                            <p>{item.name}</p>
                            <ChevronRightIcon className="arrow" />
                        </div>
                    ))}
                </div>
            </div>
            <Footer scroll={searchHeight ? logo : true} />
        </div>
    )
}

Search.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles),
    withTranslation()
)(Search)
