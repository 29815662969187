import { IMAGES } from '../../assets/images/images.js';

export default theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        objectFit: 'contain',
        overflow: 'hidden',
        backgroundImage: `url(${IMAGES.background})`,
        backgroundRepeat: 'repeat',
        backgroundColor: '#000000',
        zIndex: '30'
    },
    logo: {
        width: '100%',
        height: '46px', 
        display: 'flex',
        '& img': {
            height: '46px',
            objectFit: 'contain',
            margin: '0px auto',
        },
    },
    icon: {
        width: '100%',
        height: '120px', 
        display: 'flex',
        '& img': {
            height: '69px',
            objectFit: 'contain',
            margin: 'auto',
        },
    },
    text: {
        width: '422px',
        height: 'auto',
        display: 'flex',
        marginTop: '8px',
        marginBottom: '25px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 'lighter',
        textAlign: 'center',
        color: '#FFFFFF'
    },
    bottomText: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        position: 'fixed',
        bottom: '12px',
        fontSize: '13px',
        fontWeight: 'lighter',
        textAlign: 'center',
        color: '#FFFFFF'
    },
    bottomLogo: {
        width: '73px',
        marginLeft: '10px',
    }
});